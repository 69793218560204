import React from "react";
import styles from "./App.css";

export function AppOffline() {
  return (
    <div className={styles.container}>
      <h1>Insight is Offline</h1>
      <p>Insight is currently offline for scheduled maintenance.</p>
    </div>
  );
}
