import React from "react";
import styles from "./App.css";

export function NotFound() {
  return (
    <div className={styles.notFoundContainer}>
      <h1>Page not found</h1>
      <p>Sorry, we can&apos;t find that page.</p>
    </div>
  );
}
